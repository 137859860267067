/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Banner.css";
import parse from "html-react-parser";
import RigVedQuotes from "../RigvedQuotes";

export default function Banner(props) {
  
  const {title, nothingText, para} = props?.data?.banner;
  const [showRigVedQuotes, setShowRigVedQuotes] = useState(false);

  return (
    <>
      <div className="banner">
        <Container className="bannercontainer">
          <Row className="banner-row">
           <Col sm={12} md={12} lg={12}>
          <h1>{parse(title)}</h1>
          <div className="nothingText">
              <p>
              <span
                variant="primary"
                className="brandTagLine"
                onClick={() => {
                  setShowRigVedQuotes(true);
                }}
                onMouseOver={() => {
                  setShowRigVedQuotes(true);
                }}
                onMouseOut={() => {
                  setShowRigVedQuotes(false);
                }}
                aria-label="Brand Tag Line"
                >
               {parse(nothingText)}
               </span>
              </p>
              <RigVedQuotes showRigVedQuotes={showRigVedQuotes} />
              </div>
              <p className='banner-para'>
              {parse(para)}
              </p>
           </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
