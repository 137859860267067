/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect, Suspense, lazy } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
    IMG_CDN_RESOURCE,
    getContactUsData,
    htmlDecode,
    convertHTMLEntity,
    getDBANotApprovedStateList,
    replaceSunWestInfoWithMortgagePossibleInfo,
    replaceSunWestInfoWithMortgagePossibleDivisionInfo
} from "../Utils";
import Loading from "../Loading";
import CommonBgComp from "../CommonBgComp";
import axios from "axios";
import "./index.css";

const Warranty = lazy(() => import("../Warranty"));

export default function Disclaimer() {
    const [licenseData, setLicenseData] = useState(null);
    const [contactUsData, setContactUsData] = useState(null);
    const [dbaNotApprovedStateList, setDBANotApprovedStateList] = useState(null);
    const imgCDNURL = process.env.REACT_APP_CDN + "angelai-life/";

    useEffect(() => {
        /**
         * Fetches contact us data asynchronously and sets it in the component state.
         *
         * @return {Promise<void>} Resolves when the contact us data is fetched and set.
         */
        const fetchContactUsData = async () => {
            const contactUsData = await getContactUsData();
            if (contactUsData) {
                setContactUsData(contactUsData);
            }
        }

        /**
         * Fetches the page details asynchronously.
         *
         * @return {Promise<void>} Resolves when the page details are fetched.
         */
        const fetchPageDetails = async () => {
            // get license data
            await axios.post(
                process.env.REACT_APP_STATE_DISCLAIMERS,
                {}
            ).then((response) => {
                setLicenseData(response.data);
                // console.log("license data: ", response.data);
            }).catch((error) => {
                console.log("Unable to fetch license data: ", error);
            });

            await fetchContactUsData();
        }

        fetchPageDetails();

    }, []);

    useEffect(() => {
        const fetchDBANotApprovedStateList = async () => {
            try {
                const dbaNotApprovedList = await getDBANotApprovedStateList();
                setDBANotApprovedStateList(dbaNotApprovedList);
            } catch (error) {
                // Handle error
                console.error("Error fetching data:", error);
                setDBANotApprovedStateList(null);
            }
        };

        fetchDBANotApprovedStateList();
    }, [dbaNotApprovedStateList]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    let licenseContent = null;
    if (licenseData !== "" && licenseData !== null && licenseData !== undefined && licenseData.length > 0) {
        licenseContent = licenseData.map((ele, index) => {
            let removeChars = ele.DISCLAIMER.replace("â€‹", "''")
            const disc = htmlDecode(removeChars)
            const convertedDescription = convertHTMLEntity(disc);
            let updatedDescription = replaceSunWestInfoWithMortgagePossibleInfo(convertedDescription);

            return (
                <Row key={index}>
                    <Col sm={12} className="mb-3">
                        <p className="disclaimer-product-states">{ele.STATE}</p>
                        <div className="license_content">
                            <p>
                                {updatedDescription}
                            </p>
                        </div>
                    </Col>
                </Row>
            )
        });
    }

    return (
        <div>
            {contactUsData ? (
                <div className="disclaimer-content">
                    <div>
                        <CommonBgComp
                            // backgroundImage={imgCDNURL + "licenses-bg.webp"}
                            text="Licenses"
                            fontSizeDesktop={90}
                            fontSizeTablet={50}
                            fontSizeMobile={30}
                        />
                    </div>
                    <Container className="EAM-disclaimer-container">
                        <Suspense fallback={<Loading />}>
                            <Warranty />
                        </Suspense>
                        <Row>
                            <Col sm={12} className="mb-3">
                                <h1 className="disclaimer-product-heading" lang="en">

                                    AngelAi.Life, Powered by Sun West Mortgage Company, Inc. NMLS 3277

                                </h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={4} className="mb-3">
                                <Image
                                    src={imgCDNURL +"angelai-life-logo.webp"}
                                    alt="Evaluation Mortgage Logo Icon"
                                    width="490px"
                                    height="398px"
                                    className="img-fluid evolutionai-disclaimer-logo"
                                    loading="lazy"
                                />
                            </Col>
                            <Col sm={12} md={7} className="stateLicenseInfo mb-3">
                                <h2>State Licenses </h2>
                                <p>
                                    Licenses can be verified at :{" "}
                                    <a
                                        href="https://www.nmlsconsumeraccess.org"
                                        target="_self"
                                        rel="noopener noreferrer"
                                        aria-label="www.nmlsconsumeraccess.org"
                                    >
                                        www.nmlsconsumeraccess.org
                                    </a>
                                </p>
                                <p>In all jurisdictions, the main licensed location of AngelAi.Life is {' '}
                                    {`${contactUsData.address.googleMapsUrl}` ?
                                        <a
                                            href={`${contactUsData.address.googleMapsUrl}`}
                                            target="_self"
                                            rel="noopener noreferrer"
                                            aria-label="Mortgage Possible Google Maps Location"
                                            className="googleMapsUrl"
                                        >
                                            <span>{contactUsData.address.fullAddress}</span>
                                        </a>
                                        :
                                        <span>{`${contactUsData.address.fullAddress}`}</span>
                                    }
                                    , Phone:{" "}
                                    <a
                                        href={"tel:" + `8004537884`}
                                        aria-label="Mortgage Possible Contact Number"
                                        className="swmcPhoneNumber"
                                    >
                                        <span>(800) 453-7884</span>
                                    </a>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="affilationInfo mb-3">
                                <p>
                                    Our products and services have no affiliation with or endorsement from any government
                                    agency or body. The housing and residential financing offered herein is open to all
                                    without regard to race, color, religion, gender, sexual orientation, handicap, familial
                                    status or national origin. Being High Risk Loans, Non Conforming Jumbo loans may include
                                    higher interest rates, closing costs, interest-only periods, or prepayment penalties.
                                    Consumers with a Reverse Mortgage can lose their home if they fail to comply with the loan
                                    terms, which include, among other terms, paying property taxes, homeowners insurance and
                                    HOA dues (if applicable) on time, maintaining the subject property as their primary home,
                                    etc.
                                </p>
                            </Col>
                        </Row>
                        {licenseContent ? licenseContent : <Loading />}
                    </Container>
                </div>
            ) : (
                <Container>
                    <Row>
                        <Col sm={12} className="loadingSpinner text-center">
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
}