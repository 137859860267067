import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Banner from '../Banner/BannerSubPage';

const CustomComponent = ({ backgroundImage, text, fontSizeDesktop, fontSizeTablet, fontSizeMobile }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = backgroundImage;
    link.as = 'image';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [backgroundImage]);

  return (
    <div>
       <Banner />
    <div className="CommonBgComp-custom-component">
      <div 
        className="CommonBgComp-text-overlay"
        style={{
          '--fontSizeDesktop': `${fontSizeDesktop}px`,
          '--fontSizeTablet': `${fontSizeTablet}px`,
          '--fontSizeMobile': `${fontSizeMobile}px`
        }}
      >
        {text}
      </div>
    </div>
    </div>
  );
};

CustomComponent.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  fontSizeDesktop: PropTypes.number.isRequired,
  fontSizeTablet: PropTypes.number.isRequired,
  fontSizeMobile: PropTypes.number.isRequired,
};

export default CustomComponent;
