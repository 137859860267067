import {Container, Row, Col} from "react-bootstrap";
import { Suspense, useEffect, lazy } from 'react';
import { Rates } from "rate-quotes";
import "./Body.css";

const Form = lazy(()=>import('../SignUpForm/SignUpForm'))


const Body = ()=>{

    useEffect(() => {
        const iframe = document.getElementById('iframeContactUs');
    
        if (iframe) {
          window.iFrameResize(
            {
              log: false,
              checkOrigin: false,
              autoResize: true,
              heightCalculationMethod: 'bodyScroll',
            },
            iframe
          );
        }
      }, []);

    return(
        <div>
             <div id="" >
<Container >
  <Row className='body-row' >
    <Col xs={11} sm={11} md={9} xl={7} lg={7} className="leftBannerDiv"  >
      <Suspense fallback={<div>Loading...</div>}>
        <Rates leadSource="NET007" />
        </Suspense>
    </Col>
    <Col xs={12} sm={12} md={9} xl={5} lg={5} className="form-body-col"  >
    <Suspense fallback={<div style={{textAlign:'center'}}>Loading...</div>}>
     <Form />
     </Suspense>
    </Col>
  </Row>
</Container>

</div>

            </div>
    )
}

export default Body;