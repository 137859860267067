/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from "react";
import "./index.css";

/**
 * The UserWay Accessibility Menu. Loaded on multiple pages to ensure ada compliance.
 * This includes both the script to attach the widget and the custom trigger to open it.
 * More information can be found here: https://userway.org, https://userway.org/tutorials/product-guides/custom-trigger/, https://userway.org/docs.
 */

const UserWayAccessibilityMenu = ({
  color,
  width,
  height,
}) => {
  let accessibilityIcon;

  switch (color) {
    case 'white':
      accessibilityIcon = process.env.REACT_APP_CDN + "swmc/images/accessibility-icon-wheel_left_white.svg";
      break;
    case 'black':
      accessibilityIcon = process.env.REACT_APP_CDN + "swmc/images/accessibility-icon-wheel_left_black.svg";
      break;
    case 'orange':
      accessibilityIcon = process.env.REACT_APP_CDN + "swmc/images/accessibility-icon-wheel_left_orange.svg";
      break;
    case 'gray':
      accessibilityIcon = process.env.REACT_APP_CDN + "swmc/images/accessibility-icon-wheel_left_gray.svg";
      break;
  }

  // jsx
  return (
    <div className="userWayContainer">
      <button
        id="UserWayAccessibilityMenu"
        title="Accessibility Menu"
        className="userWayBackGroundBody"
        onClick={() => {
          /**
           * This function was provided by UserWay. It needs the ignores to prevent compilation errors.
           * It allows the trigger to still function when routing without a full refresh.
           */
          // eslint-disable-next-line no-undef
          UserWay.widgetToggle();
        }}
      >
        <img src={accessibilityIcon} alt="Open the Accessibility Menu"/>
      </button>
    </div>
  );
};

export default UserWayAccessibilityMenu;