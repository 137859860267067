import {useState, useEffect} from "react";

const useData = ()=>{

    const [data, setData] = useState(null);
    useEffect(()=>{
     fetchData();
    },[])

    
    const fetchData = async() =>{
        try{
            const response = await fetch(process.env.REACT_APP_CDN+"angelai-life/content.json");
            const jsonData = await response.json();
        setData(jsonData);
        } catch (error) {
            window.location.replace("https://www.swmc.com");
        }
    }

    return data;
}

export default useData;