import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
import useData from "./components/Utils/useData";
import HeaderNavbar from "./components/Header";
import Disclaimer from "./components/Disclaimer";
import TexasDisclosure from "./components/TexasDisclosure";
import TermsAndConditions from "./components/TermsAndConditions";
import Privacy from "./components/PrivacyPolicy/PrivacyPolicy";
import Home from "./Home";
import { ModalProvider } from "./components/Utils/ModalContext";

const LoanProgram = lazy(() => import("./components/SubPages/LoanPrograms/LoanProgramsHome"));
const MortgageCalculator = lazy(() => import("./components/SubPages/MortgageCalculator/HomeBuyersMortgage/index"));
// const FixedRateCalculators = lazy(() => import("./components/SubPages/MortgageCalculator/FixedRateCalculator"));
// const AffordabilityCalculator = lazy(() => import("./components/SubPages/MortgageCalculator/AffordabilityCalculator"));
// const RefinanceCalculator = lazy(() => import("./components/SubPages/MortgageCalculator/RefinanceCalculator"));
// const RentCalculator = lazy(() => import("./components/SubPages/MortgageCalculator/RentCalculator"));
const BiweeklyCalculator = lazy(() => import("./components/SubPages/MortgageCalculator/BiweeklyCalculator"));
const HomeBuyersGuide = lazy(() => import("./components/SubPages/HomeBuyersGuide"));
const LoanProgramsConventional = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsConventional"));
const LoanProgramsFHA = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsFHA"));
const LoanProgramsInvestment = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsInvestment"));
const LoanProgramsJumbo = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsJumbo"));
const LoanProgramsRenovation = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsRenovation"));
const LoanProgramsRefinancing = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsRefinancing"));
const LoanProgramsHecm = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsHecm"));
const LoanProgramsVA = lazy(() => import("./components/SubPages/HomeBuyersGuide/LoanProgramsVA"));
const HomeBuyersDocuments = lazy(() => import("./components/SubPages/HomeBuyersDocuments/index"));
const HomeBuyersRefinancing = lazy(() => import("./components/SubPages/HomeBuyersRefinancing/index"));
const MortgageProcess = lazy(() => import("./components/SubPages/MortagageProcess/index"));
const TruTerms = lazy(() => import("./components/TruTerms/index"));
const Footer = lazy(() => import("./components/Footer/index"));

function App() {
  const appVersion = appversion.version;
  const [showOtherComponents, setShowOtherComponents] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;

      if (percentage > 50) {
        setShowOtherComponents(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const lowercaseUrl = window.location.href.toLowerCase();
    if (window.location.href !== lowercaseUrl) {
      window.location.href = lowercaseUrl;
    }
  }, []);

  const data = useData();

  if (data === null) {
    return <div />;
  }

  const loadingStyle = {
    marginTop: '4rem',
    textAlign: 'center'
  };

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <ModalProvider>
        <Router>
          <HeaderNavbar data={data} />
          <div style={{ minHeight: "calc(100vh - 200px)" }}>
            <Suspense fallback={<div style={loadingStyle}>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/loan-programs" element={<LoanProgram />} />
                <Route path="/mortgage-calculators" element={<MortgageCalculator />} />
                {/* <Route path="/fixed-calculator" element={<FixedRateCalculators />} />
                <Route path="/refinance-calculator" element={<RefinanceCalculator />} />
                <Route path="/affordability-calculator" element={<AffordabilityCalculator />} />
                <Route path="/rent-vs-buy" element={<RentCalculator />} /> */}
                <Route path="/biweeklycalculator" element={<BiweeklyCalculator />} />
                <Route path="/home-buying" element={<HomeBuyersGuide />} />
                <Route path="/conventional-loans" element={<LoanProgramsConventional />} />
                <Route path="/fha-loans" element={<LoanProgramsFHA />} />
                <Route path="/investment-loans" element={<LoanProgramsInvestment />} />
                <Route path="/jumbo-loans" element={<LoanProgramsJumbo />} />
                <Route path="/renovation-loans" element={<LoanProgramsRenovation />} />
                <Route path="/refinance" element={<LoanProgramsRefinancing />} />
                <Route path="/reverse-mortgage" element={<LoanProgramsHecm />} />
                <Route path="/va-loans" element={<LoanProgramsVA />} />
                <Route path="/documents" element={<HomeBuyersDocuments />} />
                <Route path="/refinancing-guide" element={<HomeBuyersRefinancing />} />
                <Route path="/mortgage-process" element={<MortgageProcess />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/Txdis" element={<TexasDisclosure />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/tru-terms" element={<TruTerms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            </Suspense>
          </div>
          {showOtherComponents && (
            <Suspense fallback={<div>Loading...</div>}>
              <Footer fetchpriority="low" />
            </Suspense>
          )}
        </Router>
      </ModalProvider>
    </CacheBuster>
  );
}

export default App;
